@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    @apply h-full;
    @apply font-body;
  }

  #__next {
    @apply h-full !important;
  }
  :root {
    --background: 0 0% 100%;

    --foreground: 224 71.4% 4.1%;

    --card: 0 0% 100%;

    --card-foreground: 224 71.4% 4.1%;

    --popover: 224 71.4% 4.1%;

    --popover-foreground: 224 71.4% 4.1%;

    --primary: 220.9 39.3% 11%;

    --primary-foreground: 210 20% 98%;

    --secondary: 220 14.3% 95.9%;

    --secondary-foreground: 220.9 39.3% 11%;

    --muted: 220 14.3% 95.9%;

    --muted-foreground: 220 8.9% 46.1%;

    --accent: 220 14.3% 95.9%;

    --accent-foreground: 220.9 39.3% 11%;

    --destructive: 0 84.2% 60.2%;

    --destructive-foreground: 210 20% 98%;

    --border: 220 13% 91%;

    --input: 220 13% 91%;

    --ring: 224 71.4% 4.1%;

    --chart-1: 12 76% 61%;

    --chart-2: 173 58% 39%;

    --chart-3: 197 37% 24%;

    --chart-4: 43 74% 66%;

    --chart-5: 27 87% 67%;

    --radius: 0.5rem;
  }
  .dark {
    --background: 224 71.4% 4.1%;

    --foreground: 210 20% 98%;

    --card: 224 71.4% 4.1%;

    --card-foreground: 210 20% 98%;

    --popover: 224 71.4% 4.1%;

    --popover-foreground: 210 20% 98%;

    --primary: 210 20% 98%;

    --primary-foreground: 220.9 39.3% 11%;

    --secondary: 215 27.9% 16.9%;

    --secondary-foreground: 210 20% 98%;

    --muted: 215 27.9% 16.9%;

    --muted-foreground: 217.9 10.6% 64.9%;

    --accent: 215 27.9% 16.9%;

    --accent-foreground: 210 20% 98%;

    --destructive: 0 62.8% 30.6%;

    --destructive-foreground: 210 20% 98%;

    --border: 215 27.9% 16.9%;

    --input: 215 27.9% 16.9%;

    --ring: 216 12.2% 83.9%;

    --chart-1: 220 70% 50%;

    --chart-2: 160 60% 45%;

    --chart-3: 30 80% 55%;

    --chart-4: 280 65% 60%;

    --chart-5: 340 75% 55%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

[data-rmiz-modal-overlay='visible'] {
  @apply bg-gray-700/80;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes gradient-xy {
  0% {
    background-position: 0% 0%;
  }
  25% {
    background-position: 100% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  75% {
    background-position: 0% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

@keyframes gradient-text {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-gradient {
  background-size: 200% auto;
  animation: gradient 8s ease infinite;
}

.animate-gradient-xy {
  background-size: 400% 400%;
  animation: gradient-xy 15s ease infinite;
}

.animate-gradient-text {
  background-size: 200% auto;
  animation: gradient-text 4s ease infinite;
}

.animate-fade-in {
  animation: fade-in 0.6s ease-out forwards;
}
